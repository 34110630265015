import { StepDefinition, IStepDefinitionOptions, IOfflineStepDefinition, type ISyncOptions } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { PropertyConfigSyncProgress, type IPropertyConfigSyncProgressJSON, type IPropertyConfigSyncProgressOptions } from 'o365.pwa.modules.client.steps.PropertyConfigSyncProgress.ts';
import { SyncStatus } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { UIFriendlyMessage } from 'o365.pwa.modules.UIFriendlyMessage.ts';
// import { getOrCreateDataObject } from 'o365-dataobject';
// import { app } from 'o365-modules';
import { GroupStepDefinition } from 'o365.pwa.modules.client.steps.GroupStepDefinition.ts';
import { DataObjectStepDefinition } from 'o365.pwa.modules.client.steps.DataObjectStepDefinition.ts';
import { $t } from 'o365-utils';

import DataObjectOfflineInitializer from 'o365.pwa.modules.client.DataObjectOfflineInitializer.ts';
// import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';

import { type SyncType } from "o365.pwa.types.ts";
import type { PropertyConfig } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import type { RecordSourceOptions, DataObjectDefinitionFieldType } from 'o365-dataobject';
// import type Database from 'o365.pwa.modules.client.dexie.objectStores.Database2.ts';
// import type ObjectStore from 'o365.pwa.modules.client.dexie.objectStores.ObjectStore.ts';
// import type Index from 'o365.pwa.modules.client.dexie.objectStores.Index.ts';

import 'o365.dataObject.extension.Offline.ts';

// export const SystemPropertiesFields = [
//     'ID', 'Name', 'Title', 'Caption', 'DataType', 'InputEditor',
//     'Config', 'HasLookupValues', 'Group', 'Description', 'WhereClause', 'IsInformation',
//     'IsUrl', 'Placeholder', 'Format', 'Unit', 'MasterProperty', 'MasterDetailBinding', 'Rows',
//     'Url', 'Tooltip_ID'
// ] as const;

export interface IPropertyConfigSyncStepDefinition extends IStepDefinitionOptions {
}

export class PropertyConfigSyncStepDefinition extends StepDefinition implements IOfflineStepDefinition<PropertyConfigSyncProgress> {
    public readonly IOfflineStepDefinition = 'IOfflineStepDefinition';

    constructor(options: IPropertyConfigSyncStepDefinition) {
        super({
            stepId: options.stepId,
            title: options.title,
            dependOnPreviousStep: options.dependOnPreviousStep,
            vueComponentName: 'PropertyConfigSyncProgress',
            vueComponentImportCallback: async () => {
                return await import('o365.pwa.vue.components.steps.PropertyConfigSyncProgress.vue');
            }
        });
    }

    public toRunStepDefinition(): PropertyConfigSyncStepDefinition {
        return new PropertyConfigSyncStepDefinition({
            stepId: this.stepId,
            dependOnPreviousStep: this.dependOnPreviousStep,
            title: this.title
        });
    }

    generateStepProgress(options?: IPropertyConfigSyncProgressJSON | IPropertyConfigSyncProgressOptions, syncType?: SyncType): PropertyConfigSyncProgress {
        return new PropertyConfigSyncProgress({
            syncType: syncType,
            ...options ?? {},
            title: this.title,
            vueComponentName: this.vueComponentName,
            vueComponentImportCallback: this.vueComponentImportCallback
        });
    }

    public async syncOffline(options: ISyncOptions<PropertyConfigSyncProgress>): Promise<void> {
        try {
            const getPwaVueAppInstance = options.getPwaVueAppInstance;

            if (getPwaVueAppInstance) {
                const vueApp = getPwaVueAppInstance();

                try {

                    if (!vueApp._context.components[this.vueComponentName]) {
                        const vueComponent = await this.vueComponentImportCallback();

                        vueApp.component(this.vueComponentName, vueComponent.default)
                    }
                } catch (reason) {
                    console.error(reason);
                }
            }

            options.stepProgress.propertyConfigSyncHasStarted = true;

            const propertyConfigs = this.getPropertyConfigs(options.syncProgress.customData.propertyConfigs ?? new Set());
            const propertyConfigViewNames= options.syncProgress.customData.dataObjectPropertyViewNames;

            if (!(propertyConfigs instanceof Set) || !(propertyConfigViewNames instanceof Set)) {
                throw new Error('propertyConfigs or dataObjectPropertyViewNames is not present');
            }

            if (propertyConfigs.size === 0) {
                return;
            }

            const propertyDataObjectConfigs = new Map<string, any>();
            
            let propertyDataObjectConfigForOrgUnit = undefined;
            let propertyDataObjectConfigForObject = undefined;

            for (let propertyConfig of propertyConfigs) {
                switch (propertyConfig.Type.toLowerCase()) {
                    case 'lookup': {
                        const dataObjectConfig = this.createPropertyLookupDataObjectConfig(propertyConfig);
                        
                        propertyDataObjectConfigs.set(dataObjectConfig.id, dataObjectConfig);
                        break;
                    }
                    case 'orgunit': {
                        propertyDataObjectConfigForOrgUnit = this.createPropertyOrgUnitLookupDataObjectConfig();
                        
                        propertyDataObjectConfigs.set(propertyDataObjectConfigForOrgUnit.id, propertyDataObjectConfigForOrgUnit);
                        break;
                    }
                    case 'object': {
                        propertyDataObjectConfigForObject = this.createPropertyObjectLookupDataObjectConfig();
                        
                        propertyDataObjectConfigs.set(propertyDataObjectConfigForObject.id, propertyDataObjectConfigForObject);
                        break;
                    }
                }
            }

            const propertyBindingDataObjectConfig = this.createPropertyBindingLookupDataObjectConfig();

            propertyDataObjectConfigs.set(propertyBindingDataObjectConfig.id, propertyBindingDataObjectConfig);

            await DataObjectOfflineInitializer.initializeOfflineDataObjects(propertyDataObjectConfigs);

            const stepDefinition = this.createPropertyGroupStepDefinition({
                dataObjectIds: Array.from(propertyDataObjectConfigs.values())
                    .filter((dataObjectConfig: any) => ![ 'dsO365_OFFLINE_PropertiesEditorsWithBinding', 'dsO365_OFFLINE_PropertiesLookup_OrgUnits', 'dsO365_OFFLINE_PropertiesLookup_Objects' ].includes(dataObjectConfig.id))
                    .map((dataObjectConfig: any) => ({ dataObjectId: dataObjectConfig.id, viewName: dataObjectConfig.viewName })),
                includeObjectLookup: !!propertyDataObjectConfigForObject,
                includeOrgUnitLookup: !!propertyDataObjectConfigForOrgUnit,
                propertyConfigViewNames: propertyConfigViewNames,
            });

            const stepProgress = stepDefinition.generateStepProgress(undefined, options.syncRunDefinition.syncType);

            const stepIndex = options.currentIndex + 1;

            for (const [key, value] of options.dependencyMapping) {
                if (value >= stepIndex) {
                    options.dependencyMapping.set(key, value + 1);
                }
            }

            options.dependencyMapping.set(stepDefinition.stepId, stepIndex);

            for (let i = stepIndex; i < options.syncRunDefinition.steps.length; i++) {
                options.syncRunDefinition.steps[i].dependOnPreviousStep.push(stepDefinition.stepId);
            }

            options.syncRunDefinition.steps.splice(stepIndex, 0, stepDefinition);
            options.syncProgress.resourcesProgress.splice(stepIndex, 0, stepProgress);

            options.stepProgress.propertyConfigSyncHasCompleted = true;
        } catch (reason: any) {
            if (Array.isArray(reason)) {
                for (var e of reason) {
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', `${e.ErrorMessage}`));
                }
            } else {
                options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', `Try again or contact support if the issue does not get resolved. ${reason}`));
            }

            options.stepProgress.propertyConfigSyncHasErrors = true;
            options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            options.stepProgress.errors.push(reason);
        }
    }


    private createPropertyBindingLookupDataObjectConfig() {
        return {
            id: 'dsO365_OFFLINE_PropertiesEditorsWithBinding',
            viewName: "sviw_System_OfflinePropertiesEditorsWithBinding",
            fields: [
                { name: 'ID' },
                { name: 'Name' },
                { name: 'Title' },
                { name: 'Caption' },
                { name: 'DataType' },
                { name: 'InputEditor' },
                { name: 'Config' },
                { name: 'HasLookupValues' },
                { name: 'Group' },
                { name: 'Description' },
                { name: 'WhereClause' },
                { name: 'IsInformation' },
                { name: 'IsUrl' },
                { name: 'Placeholder' },
                { name: 'Format' },
                { name: 'Unit' },
                { name: 'MasterProperty' },
                { name: 'MasterDetailBinding' },
                { name: 'Rows' },
                { name: 'Url' },
                { name: 'Tooltip_ID' },
                { name: "PropertyViewName" },
                { name: "PropertyUniqueTableName" },
                { name: "PropertyBinding" },
                { name: "ViewName" },
                { name: "PrimKey" },
                { name: "PropertyView_PrimKey" },
                { name: "WhereObject" },
                { name: "Required" },
                { name: "ReadOnly" }
            ],
            maxRecords: -1,
            offline: {
                enableOffline: true,
                initializeDataObject: true,
                jsonDataVersion: 1,
                objectStoreIdOverride: 'dsO365_OFFLINE_PropertiesEditorsWithBinding',
                generateOfflineData: false,
                subConfigs: { data: { } },
                fieldConfig: {
                    PrimKey: {
                        pwaCompoundId: 1,
                        pwaIsPrimaryKey: true,
                        pwaUseIndex: true
                    },
                    PropertyView_PrimKey: {
                        pwaCompoundId: 1,
                        pwaUseIndex: true,
                        pwaIsPrimaryKey: true
                    }
                }
            },
        }
    }

    private createPropertyOrgUnitLookupDataObjectConfig() {
        return {
            id: 'dsO365_OFFLINE_PropertiesLookup_OrgUnits',
            viewName: 'stbv_System_OrgUnits',
            fields: [
                { name: "PrimKey", type: "string" },
                { name: "ID", type: "number" },
                { name: "IdPath", type: "string" },
                { name: "OrgUnit", type: "string" },
                { name: "Closed", type: "date" },
                { name: "Name", type: "string" },
                { name: "Title", type: "string" },
                { name: "Domain_ID", type: "number" },
                { name: "Level", type: "number" },
                { name: "UnitType", type: "string" },
                { name: "Parent", type: "string" },
                { name: "AccessIdPath", type: "string" }
            ],
            offline: {
                enableOffline: true,
                initializeDataObject: true,
                jsonDataVersion: 1,
                objectStoreIdOverride: 'dsO365_OFFLINE_PropertiesLookup_OrgUnits',
                generateOfflineData: false,
                appIdOverride: null,
                databaseIdOverride: null,
                generateOfflineDataProcedureNameOverride: null,
                subConfigs: {
                    data: {
                        loadRecents: true,
                        distinctRows: true,
                        maxRecords: 25
                    },
                    tree: {
                        selectFirstRowOnLoad: false,
                        loadRecents: false,
                        maxRecords: 25
                    },
                }
            }
        };
    }

    private createPropertyObjectLookupDataObjectConfig() {
        return {
            id: 'dsO365_OFFLINE_PropertiesLookup_Objects',
            viewName: 'aviw_Assets_ObjectsLookup',
            fields: [
                { name: "ID" },
                { name: "PrimKey" },
                { name: "Name" },
                { name: "Description" },
                { name: "ObjectType" },
                { name: "ObjectType_ID", type: "number", sortOrder: 1, sortDirection: "asc" },
                { name: "TypeAndName" },
                { name: "SuppliedBy" },
                { name: "SuppliedBy_ID", type: "number" },
                { name: "InstalledBy_ID", type: "number" },
                { name: "InstalledBy" },
                { name: "OrgUnit" },
                { name: "OrgUnit_ID", type: "number" },
                { name: "Component" },
                { name: "Component_ID", type: "number" },
                { name: "Status" },
                { name: "UniqueID" },
                { name: "IsBuilding", type: "boolean" },
                { name: "IsRoom", type: "boolean" },
                { name: "IsSite", type: "boolean" },
                { name: "IsLocation", type: "boolean" },
                { name: "IsSystem", type: "boolean" },
                { name: "IsArea", type: "boolean" },
            ],
            offline: {
                enableOffline: true,
                initializeDataObject: true,
                jsonDataVersion: 1,
                objectStoreIdOverride: 'dsO365_OFFLINE_PropertiesLookup_Objects',
                generateOfflineData: false,
                appIdOverride: null,
                databaseIdOverride: null,
                generateOfflineDataProcedureNameOverride: null,
                subConfigs: {
                    data: {
                        distinctRows: false,
                        disableAutoLoad: true,
                        selectFirstRowOnLoad: true,
                        maxRecords: 50,
                        dynamicLoading: true,
                        disableLayouts: false,
                    }
                }
            }
        };
    }

    private createPropertyLookupDataObjectConfig(config: PropertyConfig) {
        const dataObjectId = "dsO365_OFFLINE_PropertiesLookup_" + config.ViewName;
        const fields = this.parseColumnsFromString(config.Columns!);

        return {
            id: dataObjectId,
            viewName: config.ViewName,
            fields: fields,
            offline: {
                enableOffline: true,
                initializeDataObject: true,
                jsonDataVersion: 1,
                objectStoreIdOverride: dataObjectId,
                generateOfflineData: false,
                appIdOverride: null,
                databaseIdOverride: null,
                generateOfflineDataProcedureNameOverride: null,
                subConfigs: {
                    data: {
                        distinctRows: false,
                        disableAutoLoad: true,
                        selectFirstRowOnLoad: true,
                        maxRecords: 50,
                        dynamicLoading: true,
                    }
                }
            }
        }
    }

    private createPropertyGroupStepDefinition(options: {
        includeOrgUnitLookup: boolean,
        includeObjectLookup: boolean,
        dataObjectIds: Array<{ dataObjectId: string; viewName: string; }>,
        propertyConfigViewNames: Set<string>
    }): GroupStepDefinition {
        const steps = new Array<StepDefinition>();

        steps.push(new DataObjectStepDefinition({
            stepId: 'PropertyBinding',
            title: $t('Property Bindings'),
            dataObjectId: 'dsO365_OFFLINE_PropertiesEditorsWithBinding',
            onBeforeSync: async (_dataObject, _memory, requestOptions) => {
                requestOptions.loadPropertyBindingWhereObjects = true;
                requestOptions.rowCountOptions.dataObjectOptions.whereClause = `ViewName IN (${Array.from(options.propertyConfigViewNames, (view) => `'${view}'`)})`;
                requestOptions.retrieveOptions.dataObjectOptions.whereClause = `ViewName IN (${Array.from(options.propertyConfigViewNames, (view) => `'${view}'`)})`;
            }
        }));

        if (options.includeOrgUnitLookup) {
            steps.push(new DataObjectStepDefinition({
                stepId: 'PropertyOrgUnitLookup',
                title: $t('Property OrgUnit Lookup'),
                dataObjectId: 'dsO365_OFFLINE_PropertiesLookup_OrgUnits',
                onBeforeSync: async (_dataObject, _memory, requestOptions) => {
                    requestOptions.rowCountOptions.dataObjectOptions.definitionProc = "astp_Assets_ObjectsLookupDefintion";
                    requestOptions.retrieveOptions.dataObjectOptions.definitionProc = "astp_Assets_ObjectsLookupDefintion";
                }
            }));
        }

        if (options.includeObjectLookup) {
            steps.push(new DataObjectStepDefinition({
                stepId: 'PropertyObjectLookup',
                title: $t('Property Object Lookup'),
                dataObjectId: 'dsO365_OFFLINE_PropertiesLookup_Objects'
            }));
        }

        for (const { dataObjectId, viewName } of options.dataObjectIds) {
            const viewNameParts = viewName.split('_');

            viewNameParts.splice(0, 1);

            steps.push(new DataObjectStepDefinition({
                stepId: `PropertyLookup_${dataObjectId}`,
                title: $t('Property Lookup - {viewName}', { viewName: viewNameParts.join(' ') }),
                dataObjectId: dataObjectId
            }));
        }

        return new GroupStepDefinition({
            stepId: 'PropertyDataGroup',
            dependOnPreviousStep: ['_PropertyConfigSyncStepDefinition_'],
            steps: steps
        });
    }

    private getPropertyConfigs(propertyConfigs: Set<string>): Set<any> {
        return new Set(Array.from(propertyConfigs, (config) => JSON.parse(config)));
    }

    private parseColumnsFromString(pColumns: string): NonNullable<RecordSourceOptions['fields']> {
        return pColumns.split(',').map(column => {
            const values = column.split(':');
            return ({
                name: values[0],
                size: values[1],
                type: values[2] as DataObjectDefinitionFieldType['type']
            });
        });
    }
}
